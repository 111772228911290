// utils/importImages.js
const importAll = (requireContext) => {
  const images = {}
  requireContext.keys().forEach((key) => {
    const name = key.replace('./', '').split('.')[0] // Удаляем './' и расширение
    images[name] = requireContext(key)
  })
  return images
}

export const images = importAll(
  require.context('assets/leaks', false, /\.png$/)
)
