import React from 'react'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

// Регистрируем компоненты Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend)

type DataItem = {
  common: {
    cities?: Record<string, number>
  }
}

type Props = {
  data: DataItem[]
}

const CityChart: React.FC<Props> = ({ data }) => {
  const [filteredDatasetIndex, setFilteredDatasetIndex] = React.useState<
    number | null
  >(null)

  // Уникальные города
  const allCities = Array.from(
    new Set(data.flatMap((item) => Object.keys(item.common.cities || {})))
  )

  // Общие значения по всем объектам
  const totalCitiesValues = allCities.map((city) =>
    data.reduce((sum, item) => sum + (item.common.cities?.[city] || 0), 0)
  )

  // Создание datasets
  const datasets = data.map((item, index) => ({
    label: `Группа ${index + 1}`,
    data: allCities.map((city) => item.common.cities?.[city] || 0),
    backgroundColor: [
      '#D32F2F60', // Темно-красный
      '#1976D260', // Темно-синий
      '#388E3C60', // Темно-зеленый
      '#F57C0060', // Темно-оранжевый
      '#7B1FA260', // Темно-фиолетовый
      '#303F9F60', // Темно-голубой
      '#00fff260', // бирюзовый
      '#5D403760', // Темно-коричневый
      '#0288D160', // Темно-лазурный
      '#C2185B60', // Темно-розовый
    ][index % 10],
    borderColor: [
      '#D32F2F', // Темно-красный
      '#1976D2', // Темно-синий
      '#388E3C', // Темно-зеленый
      '#F57C00', // Темно-оранжевый
      '#7B1FA2', // Темно-фиолетовый
      '#303F9F', // Темно-голубой
      '#00fff2', // бирюзовый
      '#5D4037', // Темно-коричневый
      '#0288D1', // Темно-лазурный
      '#C2185B', // Темно-розовый
    ][index % 10],
    hidden: filteredDatasetIndex !== null && filteredDatasetIndex !== index, // Скрывать данные
  }))

  // Общие данные (Total)
  const totalDataset = {
    label: 'Всего',
    data: totalCitiesValues,
    backgroundColor: 'rgba(100, 100, 100, 0.8)',
    borderColor: 'rgba(100, 100, 100, 1)',
    borderWidth: 2,
    hidden: filteredDatasetIndex !== null, // Скрывать, если выбран объект
  }

  // Данные для графика
  const chartData = {
    labels: allCities,
    datasets: [totalDataset, ...datasets],
  }

  // Настройка опций графика
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Позволяет управлять высотой
    plugins: {
      legend: {
        position: 'top' as const,
        onClick: (e: any, legendItem: any) => {
          const index = legendItem.datasetIndex - 1 // Индекс объекта (-1, потому что 0 — это Total)
          setFilteredDatasetIndex(index === filteredDatasetIndex ? null : index) // Смена состояния
        },
        font: {
          size: 10, // Меньше для мобильных
        },
      },
      tooltip: { mode: 'index' as const, intersect: false },
    },
    scales: {
      x: { stacked: true },
      y: { beginAtZero: true, stacked: true },
    },
  }

  const dynamicHeight = Math.max(400, window.innerWidth * 0.5) // Минимум 200px, но пропорционально ширине

  return (
    <div style={{ width: '100%', height: dynamicHeight }}>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default CityChart
