import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Paper,
} from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material'
import { translations } from './translations'

type CollapsibleTableProps = {
  data: any[]
  onRowClick: (clusterId: number | null) => void
  highlighedClusterId: number | null
}

type OpenRowsState = {
  [key: string]: boolean
}

const colors = [
  '#D32F2F60', // Темно-красный
  '#1976D260', // Темно-синий
  '#388E3C60', // Темно-зеленый
  '#F57C0060', // Темно-оранжевый
  '#7B1FA260', // Темно-фиолетовый
  '#303F9F60', // Темно-голубой
  '#00fff260', // Бирюзовый
  '#5D403760', // Темно-коричневый
  '#0288D160', // Темно-лазурный
  '#C2185B60', // Темно-розовый
]

const CollapsibleTable: React.FC<CollapsibleTableProps> = ({
  data,
  onRowClick,
  highlighedClusterId,
}) => {
  const [openRows, setOpenRows] = React.useState<OpenRowsState>({})

  const handleToggle = (id: any) => {
    setOpenRows((prev: OpenRowsState) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const renderNestedObject = (value: any, rowId: any, parentKey: any) => {
    return (
      <Box margin={1}>
        <Table size='small' aria-label='nested details'>
          <TableBody>
            {Object.entries(value).map(([subKey, subValue]) => (
              <TableRow key={subKey}>
                <TableCell>{subKey}</TableCell>
                <TableCell>
                  {typeof subValue === 'object' && subValue !== null
                    ? renderCellContent(
                        subKey,
                        subValue,
                        `${rowId}-${parentKey}`
                      )
                    : String(subValue)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    )
  }

  const renderCellContent = (key: any, value: any, rowId: any) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <>
          <IconButton
            size='small'
            onClick={() => handleToggle(`${rowId}-${key}`)}
          >
            {openRows[`${rowId}-${key}`] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          <Collapse
            in={openRows[`${rowId}-${key}`]}
            timeout='auto'
            unmountOnExit
          >
            {renderNestedObject(value, rowId, key)}
          </Collapse>
        </>
      )
    }

    return value ? String(value) : <>---</>
  }

  const columnKeys = Object.keys(data[0]).filter(
    (key) => key !== 'cluster_for_singles'
  )

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            {Object.keys(data[0])
              .filter((key) => key !== 'cluster_for_singles')
              .map((key: any) => (
                <TableCell key={key} align='center'>
                  {translations?.[key] || key}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any) => (
            <TableRow
              key={item.id}
              onClick={() => {
                if (highlighedClusterId === item.id) {
                  onRowClick(null)
                } else {
                  onRowClick(item.id)
                }
              }}
              sx={{
                cursor: 'pointer',
                bgcolor:
                  highlighedClusterId && highlighedClusterId === item.id
                    ? colors[highlighedClusterId % colors.length]
                    : 'unset',
              }}
            >
              {columnKeys.map((key) => (
                <TableCell key={key} align='center' style={{ minWidth: 200 }}>
                  {item[key] !== undefined && item[key] !== null ? (
                    renderCellContent(key, item[key], item.id)
                  ) : (
                    <>---</>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CollapsibleTable
