import { Stack, styled } from '@mui/material'

export const Wrapper = styled(Stack)({
  boxSizing: 'border-box',
  height: '100vh',
  width: '100vw',
  position: 'relative',
  overflowY: 'auto',
  overflowX: 'hidden',
})

export const Sidebar = styled(Stack)(({ theme }) => ({
  width: 500,
  padding: 16,
  boxSizing: 'border-box',

  [theme.breakpoints.down('md')]: {
    width: 300,
  },
}))
