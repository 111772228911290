import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Box, Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { v4 } from 'uuid'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'

import { images } from './utils'

export const Leaks = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { leaks } = profileStore

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const [imagePaths, setImagePaths] = useState({})

  useEffect(() => {
    setImagePaths(images)
  }, [])

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (!leaks?.in_detail?.length) {
    return null
  }

  return (
    <Box sx={{ mt: 2, maxWidth: '900px', width: '100%', px: '20px' }}>
      <Stack direction='row' mb='8px'>
        <Box
          sx={{
            mr: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SubheaderIcon name='FolderSharedOutlinedIcon' />
        </Box>

        <Typography color='common.black' textAlign='start' variant='h6'>
          Утечки
        </Typography>
      </Stack>

      {leaks.in_detail.map(({ type, value, details }) => (
        <Accordion
          expanded={expanded === `${type}-${value}`}
          onChange={handleChange(`${type}-${value}`)}
          key={`${type}-${value}`}
        >
          <AccordionSummary>
            <Stack direction='row' alignItems='center'>
              <Stack mr={1} justifyContent='center' alignItems='center'>
                {type === 'phone' ? (
                  <SubheaderIcon name='LocalPhoneOutlinedIcon' />
                ) : null}
                {type === 'email' ? (
                  <SubheaderIcon name='AlternateEmailIcon' />
                ) : null}
                {type === 'grz' ? (
                  <SubheaderIcon name='PinOutlinedIcon' />
                ) : null}
              </Stack>

              <Typography color='common.black' variant='body1'>
                {value}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack
              justifyContent='space-between'
              sx={{
                mt: 1,
                width: '100%',
                maxWidth: '900px',
              }}
            >
              {[...details]
                .sort((a) => (a.description ? -1 : 0))
                .map(({ name, description }, idx) => (
                  <Stack
                    key={v4()}
                    justifyContent='space-between'
                    flexDirection='row'
                    sx={{ mt: idx ? 1 : 0 }}
                  >
                    <Stack width='50%' flexDirection='row' alignItems='center'>
                      <Typography color='common.black' textAlign='start' mr={1}>
                        {name}
                      </Typography>

                      {(imagePaths as any)?.[name] ? (
                        <Box
                          sx={{
                            width: 60,
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                          }}
                        >
                          <img
                            style={{
                              maxHeight: '100%',
                              maxWidth: '100%',
                              objectFit: 'contain',
                            }}
                            src={(imagePaths as any)?.[name]}
                            alt={name}
                          />
                        </Box>
                      ) : null}
                    </Stack>

                    <Typography
                      width='50%'
                      textAlign='end'
                      color='text.disabled'
                      sx={{ overflowWrap: 'break-word' }}
                    >
                      {description || '---'}
                    </Typography>
                  </Stack>
                ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
})
