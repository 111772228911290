export const translations: Record<string, string> = {
  id: 'Идентификатор',
  nickname: 'Ник',
  maiden_name: 'Девичья фамилия',
  city: 'Город',
  photo_200_orig: 'Фото',
  has_photo: 'Есть фото',
  quotes: 'Цитаты',
  about: 'О себе',
  status: 'Статус',
  last_seen: 'Последний раз в сети',
  career: 'Карьера',
  home_town: 'Родной город',
  relation: 'Семейное положение',
  personal: 'Личное',
  langs: 'Языки',
  universities: 'Университеты',
  schools: 'Школы',
  relatives: 'Родственники',
  sex: 'Пол',
  screen_name: 'Имя в сети',
  online: 'В сети',
  verified: 'Подтвержден',
  first_name: 'Имя',
  last_name: 'Фамилия',
  is_closed: 'Закрытый профиль',
  age: 'Возраст',
  url: 'Ссылка',
  full_name: 'Полное имя',
  contacts: 'Контакты',
  home_phone: 'Домашний телефон',
  site: 'Сайт',
  mobile_phone: 'Мобильный телефон',
  tags: 'Теги',
  platform: 'Платформа',
  time_msk: 'Время московское',
  time: 'Время',
  link_type: 'Тип связи',
  friends_count: 'Количество друзей',
  followers_count: 'Количество подписчиков',
  links_data: 'Соседей в кластере',
  cluster_id: 'Кластер',
  links_count: 'Количество связей',
  popularity_rank: 'Рейтинг популярности',
  bdate: 'День рождения',
  city_id: '_',
  group_id: '_',
  city_name: 'Город',
  company: 'Место работы',
  position: 'Должность',
  from: 'c',
  until: 'по',
  title: 'Город',
  facebook: 'Facebook ID',
  facebook_name: 'Имя профиля на Facebook',
  instagram: 'Instagram',
  livejournal: 'LiveJournal',
  skype: 'Skype',
  albums: 'Фотоальбомов',
  audios: 'Аудиозаписей',
  followers: 'Подписчиков',
  friends: 'Друзей',
  online_friends: 'Друзей онлайн',
  notes: 'Заметок',
  gifts: 'Подарков',
  groups: 'Сообществ',
  pages: 'Объектов в блоке «Интересные страницы»',
  photos: 'Фотографий',
  user_videos: 'Видеозаписей с пользователем',
  user_photos: 'Фотографий с пользователем',
  subscriptions: 'Подписок пользователя (на кого подписан).',
  videos: 'Видеозаписей',
  video_playlists: 'Плейлистов видеозаписей',
  clips: 'VK Клипов (Shorts)',
  clips_views: 'Просмотров VK клипов',
  clips_likes: 'Лайков VK клипов',
  deactivated: 'Профиль удалён/забанен',
  platfom: 'Устройство',
  time_mk: 'Был(а) онлайн',
  unit: 'Наименование (номер) части',
  unit_id: '_',
  graduate_year: 'Год выпуска',
  name: 'Название школы, вуза или места работы',
  type: 'Род деятельности',
  alcohol: 'Отношение к алкоголю',
  inspired_by: 'Источники вдохновения',
  life_main: 'Главное в жизни',
  people_main: 'Главное в людях',
  political: 'Политические предпочтения',
  religion: 'Мировоззрение',
  smoking: 'Отношение к курению',
  photo_max_res: 'Фото (макс. разрешение)',
  birth_date: 'Дата рождения',
  class: 'Класс',
  class_id: '_',
  speciality: 'Специализация',
  type_str: 'Тип места обучения',
  year_from: 'Год начала обучения',
  year_graduated: 'Год выпуска',
  year_to: 'Год окончания обучения',
  chair: '_',
  chair_name: 'Наименование кафедры',
  education_form: 'Форма обучения',
  education_form_id: '_',
  education_status: 'Текущий статус (например, «Выпускник (специалист)»)',
  education_status_id: '_',
  faculty: '_',
  faculty_name: 'Наименование факультета',
  graduation: 'Год окончания обучения',
  time_created: 'Время создания',
  source: '_',
  target: '_',
  ordered: '_',
  cluster_size: 'Людей в кластере',
  cluster_for_singles: 'Кластер для одиночек',
  open: 'Открытых',
  banned: 'Забаненных',
  closed: 'Закрытых',
  deleted: 'Удалённых',
  inner_links: 'Внутренних связей',
  outer_links: 'Внешних связей',
  clustering_coefficient: 'Коэф. кластеризации',
  closed_profiles_percent: '% закрытых профилей',
  deactivated_profiles_percent: '% удалённых/забаненных профилей',
  online_users_in_last_three_days: 'Юзеров онлайн за последние 3 дня',
  members: 'id пользователей в кластере',
  jobs: 'Места работы',
  cities: 'Города',
  genders: 'Пол',
  hometowns: 'Родной город',
  occupations: 'Род деятельности',
  age_metrics: 'Возрастные показатели',
  friend_metrics: 'Метрики по друзьям',
  follower_metrics: 'Метрики по подписчкам',
  count: 'Кол-во',
  percent: '% от числа связей в кластере',
  neighbors_count: 'Соседей в кластере',
  friends_in_cluster: 'Друзей в кластере',
  followers_in_cluster: 'Подписчиков в кластере',
  users_with_outer_links: 'Пользователей с внешними связями',
}
