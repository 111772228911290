import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'normalize.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory, BrowserHistory } from 'history'
import { RootStore } from './store/rootStore'
import '@material-design-icons/font'

import { StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'

import { queryClient } from './query-client'

import { theme } from './theme'
import { QueryClientProvider } from '@tanstack/react-query'

interface IStore {
  rootStore: RootStore
}

const rootStore = new RootStore()
export const Context = createContext<IStore>({ rootStore })
export const history: BrowserHistory = createBrowserHistory()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <Context.Provider value={{ rootStore }}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </Context.Provider>
  </BrowserRouter>
)

reportWebVitals()
