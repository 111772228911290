import './App.css'
import { observer } from 'mobx-react-lite'
import { Routes, Route } from 'react-router-dom'
import NotFound from './components/NotFound'
import { Map } from './components/Map'
import { Graph } from './components/Graph'
import { TransportInfo } from './components/TransportInfo'
import { useContext } from 'react'
import { Context } from './index'
import { Athena } from './components/Athena'
import { Profile } from './components/Profile'
import { Radar } from './components/Radar'
import React from 'react'
import { Loading } from 'components/Loading'
import { FaceMatching } from 'components/FaceMatching'
import { SocialGraph } from 'components/social-graph'
import { usePathname } from 'routes/hooks'

const App = observer(() => {
  const { rootStore } = useContext(Context)
  const { mapStore, loaderStore, tableStore, graphStore } = rootStore
  const err = mapStore.error || graphStore.error || tableStore.error

  const pathname = usePathname()

  return (
    <div className='App'>
      {!!loaderStore.isLoading && !pathname.includes('social-graph') && (
        <Loading />
      )}
      <Routes>
        {err ? (
          <Route path='*' element={<NotFound error={err} />} />
        ) : (
          <>
            <Route path='/face_matching/:data' element={<FaceMatching />} />
            <Route path='/map/:data' element={<Map />} />
            <Route path='/graph/:data' element={<Graph />} />
            <Route path='/social-graph' element={<SocialGraph />} />
            <Route path='/trip_photos/:data' element={<TransportInfo />} />
            <Route path='/athena' element={<Athena />} />
            <Route path='/profile_analytics/:data' element={<Profile />} />
            <Route
              path='/profile_grz/:data'
              element={<Profile type='profile_grz' />}
            />
            <Route
              path='/profile_telegram_id/:data'
              element={<Profile type='profile_telegram_id' />}
            />
            <Route
              path='/profile_telegram_user_name/:data'
              element={<Profile type='profile_telegram_user_name' />}
            />
            <Route
              path='/profile_grz_plus/:data'
              element={<Profile type='profile_grz_plus' />}
            />
            <Route
              path='/profile_phone/:data'
              element={<Profile type='profile_phone' />}
            />
            <Route
              path='/profile_vk_id/:data'
              element={<Profile type='profile_vk_id' />}
            />
            <Route
              path='/profile_phone_plus/:data'
              element={<Profile type='profile_phone_plus' />}
            />
            <Route path='/radar' element={<Radar />} />
          </>
        )}
      </Routes>
    </div>
  )
})

export default App
