import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import { vkKeyTranslate } from './utils'
import { UserInfoInner } from './UserInfoInner'

export const VkUserInfo = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { vkUserInfo } = profileStore

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (
    !vkUserInfo ||
    !vkUserInfo['person'] ||
    !Object.keys(vkUserInfo['person']).length
  ) {
    return null
  }

  const data: Record<keyof typeof vkKeyTranslate, any> = vkUserInfo['person']

  return (
    <Accordion
      expanded={expanded === 'userInfo'}
      onChange={handleChange('userInfo')}
    >
      <AccordionSummary>
        <Stack direction='row'>
          <Box
            sx={{
              mr: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SubheaderIcon name='PersonOutlinedIcon' />
          </Box>

          <Typography variant='body1' color='common.black'>
            Данные Пользователя Vk
          </Typography>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <UserInfoInner data={data} />
      </AccordionDetails>
    </Accordion>
  )
})
