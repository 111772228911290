import React from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend)

const ageIntervals = [
  { label: '0-18', min: 0, max: 18 },
  { label: '18-21', min: 18, max: 21 },
  { label: '21-24', min: 21, max: 24 },
  { label: '24-27', min: 24, max: 27 },
  { label: '27-30', min: 27, max: 30 },
  { label: '30-40', min: 30, max: 40 },
  { label: '40+', min: 40, max: Infinity },
]

const groupByAgeAndCluster = (
  users: Record<string, { age: number | null; cluster_id: number }>
) => {
  // Инициализируем структуру данных
  const groupedData: Record<string, Record<number, number>> = {}

  ageIntervals.forEach((interval) => {
    groupedData[interval.label] = {}
  })

  // Проходим по каждому пользователю
  Object.values(users).forEach(({ age, cluster_id }) => {
    if (age === null) {
      return
    } // Пропускаем, если возраст отсутствует

    const interval = ageIntervals.find(
      (interval) => age >= interval.min && age < interval.max
    )
    if (!interval) {
      return
    }

    if (!groupedData[interval.label][cluster_id]) {
      groupedData[interval.label][cluster_id] = 0
    }

    groupedData[interval.label][cluster_id]++
  })

  return groupedData
}

const AgeChart: React.FC<{
  users: Record<string, { age: number | null; cluster_id: number }>
}> = ({ users }) => {
  const [filteredClusterId, setFilteredClusterId] = React.useState<
    number | null
  >(null)

  const groupedData = groupByAgeAndCluster(users)

  const allClusters = Array.from(
    new Set(Object.values(users).map((user) => user.cluster_id))
  ).sort((a, b) => a - b)

  const chartLabels = Object.keys(groupedData)

  const datasets = allClusters.map((cluster_id, index) => ({
    label: `Группа ${cluster_id}`,
    data: chartLabels.map((label) => groupedData[label][cluster_id] || 0),
    backgroundColor: [
      '#D32F2F60', // Темно-красный
      '#1976D260', // Темно-синий
      '#388E3C60', // Темно-зеленый
      '#F57C0060', // Темно-оранжевый
      '#7B1FA260', // Темно-фиолетовый
      '#303F9F60', // Темно-голубой
      '#00fff260', // бирюзовый
      '#5D403760', // Темно-коричневый
      '#0288D160', // Темно-лазурный
      '#C2185B60', // Темно-розовый
    ][index % 10],
    borderColor: [
      '#D32F2F', // Темно-красный
      '#1976D2', // Темно-синий
      '#388E3C', // Темно-зеленый
      '#F57C00', // Темно-оранжевый
      '#7B1FA2', // Темно-фиолетовый
      '#303F9F', // Темно-голубой
      '#00fff2', // бирюзовый
      '#5D4037', // Темно-коричневый
      '#0288D1', // Темно-лазурный
      '#C2185B', // Темно-розовый
    ][index % 10],
    borderWidth: 1,
    hidden: filteredClusterId !== null && filteredClusterId !== cluster_id, // Скрытие по фильтру
  }))

  const chartData = {
    labels: chartLabels,
    datasets,
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        onClick: (e: any, legendItem: any) => {
          const index = legendItem.datasetIndex
          const cluster_id = allClusters[index]
          setFilteredClusterId(
            filteredClusterId === cluster_id ? null : cluster_id
          ) // Смена фильтра
        },
        font: {
          size: 10,
        },
      },
      tooltip: { mode: 'index' as const, intersect: false },
    },
    maintainAspectRatio: false, // Позволяет управлять высотой
    scales: {
      x: { stacked: true },
      y: { beginAtZero: true, stacked: true },
    },
  }

  const dynamicHeight = Math.max(400, window.innerWidth * 0.5) // Минимум 200px, но пропорционально ширине

  return (
    <div style={{ width: '100%', height: dynamicHeight }}>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default AgeChart
