import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Context } from 'index'
import { Box, Stack, Typography } from '@mui/material'
import { Pie } from 'react-chartjs-2'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import { vkKeyTranslate } from './utils'
import { InfoRecord } from './VkUserInfoRecord'
import * as S from './VkUserInfo.styled'

ChartJS.register(ArcElement, Tooltip, Legend)

const pieColors = [
  'rgba(249, 65, 68, 0.7)',
  'rgba(243, 114, 44, 0.7)',
  'rgba(248, 150, 30, 0.7)',
  'rgba(249, 132, 74, 0.7)',
  'rgba(249, 199, 79, 0.7)',
  'rgba(144, 190, 109, 0.7)',
  'rgba(67, 170, 139, 0.7)',
  'rgba(77, 144, 142, 0.7)',
  'rgba(87, 117, 144, 0.7)',
  'rgba(39, 125, 161, 0.7)',
]

const useDataByVals = (val: { [k: string]: number[] }) => {
  const labels = Object.keys(val).map((lab) => {
    if (lab.length > 45) {
      return `${lab.slice(0, 42)}...`
    }

    return lab
  })

  const count = Object.keys(val)?.length

  return {
    labels,
    datasets: [
      {
        data: Object.values(val),
        backgroundColor: pieColors,
        borderColor: pieColors,
        borderWidth: count > 1 ? 1.5 : 0,
      },
    ],
  }
}

const Relative = observer((props: any) => {
  const { full_name, url } = props

  if (full_name && url) {
    return (
      <>
        <InfoRecord
          name={full_name}
          value={
            <a href={url} target='_blank' rel='noreferrer'>
              Профиль
            </a>
          }
        />
      </>
    )
  }

  return (
    <>
      {full_name ? (
        <InfoRecord name={vkKeyTranslate.name} value={full_name} />
      ) : null}

      {url ? (
        <InfoRecord
          name={vkKeyTranslate.profile_link}
          value={
            <a href={url} target='_blank' rel='noreferrer'>
              Профиль
            </a>
          }
        />
      ) : null}
    </>
  )
})

export const VkFriendsCharts = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { vkUserInfo } = profileStore

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (!vkUserInfo || !vkUserInfo?.['friends_metrics']) {
    return null
  }

  const relatives: any[] =
    vkUserInfo['friends_metrics']?.['Возможные родственники']

  const hasNoData = useMemo(
    () =>
      !vkUserInfo?.['friends_metrics']?.['Проанализировано пользователей'] &&
      !relatives?.length &&
      (!vkUserInfo?.['friends_metrics'] ||
        !Object.entries(vkUserInfo?.['friends_metrics'])?.length),
    [relatives?.length, vkUserInfo]
  )

  if (hasNoData) {
    return null
  }

  return (
    <>
      <Accordion
        expanded={expanded === 'relatives'}
        onChange={handleChange('relatives')}
      >
        <AccordionSummary>
          <Stack direction='row'>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='DonutLargeOutlinedIcon' />
            </Box>

            <Typography variant='body1' color='common.black'>
              Статистика Друзей Vk
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack
            justifyContent='space-between'
            sx={{
              mt: 1,
              width: '100%',
              maxWidth: '900px',
            }}
          >
            {vkUserInfo?.['friends_metrics']?.[
              'Проанализировано пользователей'
            ] ? (
              <Typography variant='h6' sx={{ mb: 2 }}>
                Проанализировано пользователей:{' '}
                {
                  vkUserInfo['friends_metrics'][
                    'Проанализировано пользователей'
                  ]
                }
              </Typography>
            ) : null}

            {relatives?.length ? (
              <Stack>
                <Typography variant='body2'>Возможные родственники</Typography>

                {relatives.map((rel: any) => (
                  <Relative
                    key={Math.random()}
                    full_name={rel.full_name}
                    url={rel.url}
                  />
                ))}
              </Stack>
            ) : null}

            {vkUserInfo?.['friends_metrics'] &&
            Object.entries(vkUserInfo?.['friends_metrics'])?.length ? (
              <Stack flexDirection='row' flexWrap='wrap'>
                {Object.entries(vkUserInfo?.['friends_metrics'])
                  .filter(
                    ([key, val]: any) =>
                      key !== 'ID пользователей' &&
                      key !== 'Возможные родственники' &&
                      key !== 'Проанализировано пользователей' &&
                      val &&
                      Object.values(val)?.length
                  )
                  .map(([key, val]: any, idx) => (
                    <S.ChartContainer
                      count={Object.values(val).length}
                      key={`${key}_${idx}`}
                    >
                      <Typography variant='body2' sx={{ mb: 2 }}>
                        {key}
                      </Typography>

                      <Pie
                        options={{
                          plugins: {
                            legend: {
                              labels: {
                                boxWidth: 6,
                                boxHeight: 6,
                                padding: 8,
                                usePointStyle: true,
                              },
                            },
                          },
                        }}
                        data={useDataByVals(val)}
                        key={idx}
                      />
                    </S.ChartContainer>
                  ))}
              </Stack>
            ) : null}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  )
})
