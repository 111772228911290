import React from 'react'
import { Bubble } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(LinearScale, PointElement, Tooltip, Legend)

type User = {
  age: number | null
  cluster_id: number
  links_count: number
  full_name: string
}

const getTopUsersByCluster = (users: Record<string, User>, topN = 5) => {
  const clusters: Record<number, User[]> = {}

  // Группируем пользователей по `cluster_id`
  Object.values(users).forEach((user) => {
    if (!clusters[user.cluster_id]) {
      clusters[user.cluster_id] = []
    }
    clusters[user.cluster_id].push(user)
  })

  // Оставляем только топ-5 пользователей по `links_count` для каждого кластера
  return Object.entries(clusters).reduce(
    (acc: any, [clusterId, clusterUsers]) => {
      const uniqueLinksCount = new Set<number>()
      acc[clusterId] = clusterUsers
        .sort((a, b) => b.links_count - a.links_count) // Сортируем по убыванию links_count
        .filter((user) => {
          if (uniqueLinksCount.has(user.links_count)) {
            return false
          } // Игнорируем дубли
          uniqueLinksCount.add(user.links_count)
          return true
        })
        .slice(0, topN) // Берем только топ-N
      return acc
    },
    {} as Record<number, User[]>
  )
}

const prepareBubbleData = (topUsers: Record<number, User[]>) => {
  return Object.entries(topUsers).map(([clusterId, users], index) => ({
    label: `Группа ${clusterId}`,
    data: users.map((user) => ({
      x: Number(clusterId),
      y: user.links_count,
      r: Math.sqrt(user.links_count) * 2, // Радиус пропорционален корню из links_count
      full_name: user.full_name, // Для тултипа
    })),
    backgroundColor: [
      '#D32F2F60', // Темно-красный
      '#1976D260', // Темно-синий
      '#388E3C60', // Темно-зеленый
      '#F57C0060', // Темно-оранжевый
      '#7B1FA260', // Темно-фиолетовый
      '#303F9F60', // Темно-голубой
      '#00fff260', // бирюзовый
      '#5D403760', // Темно-коричневый
      '#0288D160', // Темно-лазурный
      '#C2185B60', // Темно-розовый
    ][index % 10],
    borderColor: [
      '#D32F2F', // Темно-красный
      '#1976D2', // Темно-синий
      '#388E3C', // Темно-зеленый
      '#F57C00', // Темно-оранжевый
      '#7B1FA2', // Темно-фиолетовый
      '#303F9F', // Темно-голубой
      '#00fff2', // бирюзовый
      '#5D4037', // Темно-коричневый
      '#0288D1', // Темно-лазурный
      '#C2185B', // Темно-розовый
    ][index % 10],
    borderWidth: 1,
  }))
}

const BubbleChart: React.FC<{ users: Record<string, User> }> = ({ users }) => {
  const topUsers = getTopUsersByCluster(users)
  const bubbleData = prepareBubbleData(topUsers)

  const chartData = {
    datasets: bubbleData,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Позволяет управлять высотой
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 10, // Меньше для мобильных
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const data = tooltipItem.raw
            return `${data.full_name}: ${data.y} связей`
          },
        },
      },
    },
    scales: {
      x: {
        title: { display: true, text: 'Группы' },
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        title: { display: true, text: 'Кол-во связей' },
        beginAtZero: true,
      },
    },
  }

  const dynamicHeight = Math.max(400, window.innerWidth * 0.5) // Минимум 200px, но пропорционально ширине

  return (
    <div style={{ width: '100%', height: dynamicHeight }}>
      <Bubble data={chartData} options={options} />
    </div>
  )
}

export default BubbleChart
