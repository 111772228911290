import React, { useMemo } from 'react'
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { translations } from './translations'
import { Image } from 'antd'

const CustomList = ({ data }: { data: Record<string, any> }) => {
  const bigPhoto = useMemo(() => data?.photo_max_res, [data?.photo_max_res])
  const smallPhoto = useMemo(() => data?.photo_200_orig, [data?.photo_200_orig])

  return (
    <List>
      {Object.entries(data)
        .filter(([key]) => key !== 'time' && key !== 'group_id' && key !== 'city_id')
        .map(([key, value]) => {
          const label = translations[key] || key

          if (bigPhoto && key === 'photo_max_res' && typeof value === 'string') {
            return (
              <ListItem sx={{ py: 0, wordWrap: 'break-word' }} key={key}>
                <ListItemAvatar>
                  <Image
                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                    preview={{ mask: false }}
                    height={40}
                    width={40}
                    src={value}
                  />
                </ListItemAvatar>
                <ListItemText primary={label} secondary='Фото профиля' />
              </ListItem>
            )
          }

          if (smallPhoto && !bigPhoto && key === 'photo_200_orig' && typeof value === 'string') {
            return (
              <ListItem sx={{ py: 0, wordWrap: 'break-word' }} key={key}>
                <ListItemAvatar>
                  <Image
                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                    preview={{ mask: false }}
                    height={40}
                    width={40}
                    src={value}
                  />
                </ListItemAvatar>
                <ListItemText primary={label} secondary='Фото профиля' />
              </ListItem>
            )
          }

          if (
            Array.isArray(value) &&
            value.every((item) => typeof item !== 'object')
          ) {
            if (!value.length) {
              return null
            }

            return (
              <ListItem sx={{ py: 0, wordWrap: 'break-word' }} key={key}>
                <ListItemText
                  primary={label}
                  secondary={value.join(', ')}
                />
              </ListItem>
            )
          }

          if (Array.isArray(value) && key === 'links_data') {
            if (!value.length) {
              return null
            }

            return (
              <ListItem sx={{ py: 0, wordWrap: 'break-word' }} key={key}>
                <ListItemText
                  primary={label}
                  secondary={
                    value.length
                      ? value.map(
                        (obj) =>
                          `${obj?.neighbors_count} (${obj?.percent ? Number(obj.percent).toFixed(1) : ''
                          }%); `
                      )
                      : 'Не указано'
                  }
                />
              </ListItem>
            )
          }

          if (typeof value === 'object' && value !== null) {
            return <CustomList data={value} key={key} />
          }

          if (typeof value === 'string' && value.includes('https://vk')) {
            return (
              <a
                style={{ paddingLeft: 16 }}
                key={key}
                target='_blank'
                rel='noreferrer'
                href={value}
              >
                Профиль
              </a>
            )
          }

          if (typeof value === 'string' && value.includes('http')) {
            return (
              <ListItem sx={{ py: 0, wordWrap: 'break-word' }} key={key}>
                <ListItemText
                  primary={label}
                  secondary={
                    <a key={key} target='_blank' rel='noreferrer' href={value}>
                      Ссылка
                    </a>
                  }
                />
              </ListItem>
            )
          }

          if (!value) {
            return null
          }

          return (
            <ListItem sx={{ py: 0, wordWrap: 'break-word' }} key={key}>
              <ListItemText
                primary={label}
                secondary={value ? String(value) : 'Не указано'}
              />
            </ListItem>
          )
        })}
    </List>
  )
}

export default CustomList
