import { Stack } from '@mui/system'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { VkFriendsCharts } from './VkFriendsCharts'
import { VkUserInfo } from './VkUserInfo'
import { Button } from 'components/common/Button'
import { useNavigate } from 'react-router-dom'

export const VkUserInfoWrapper = observer(() => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore
  const { vkUserInfo, vkUserInfoGraph } = profileStore

  const navigate = useNavigate()

  if (!vkUserInfo) {
    return null
  }

  return (
    <Stack
      sx={{
        mt: 2,
        maxWidth: '900px',
        width: '100%',
        px: '16px',
      }}
    >
      <VkFriendsCharts />

      <VkUserInfo />

      {vkUserInfoGraph ? (
        <Button
          variant='contained'
          onClick={() => navigate('/social-graph')}
          additionalSx={{
            placeSelf: 'center',
            mt: 2,
            height: 36,
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '24px',
            maxWidth: 300,
            width: '100%',
          }}
        >
          Социальный граф
        </Button>
      ) : null}
    </Stack>
  )
})
